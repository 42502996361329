<template>
  <en-table-layout
    :tableData="pageData.data"
    @selection-change="handleSelectionChange2"
  >
    <template slot="toolbar">
      <el-form-item label="状态">
        <el-select size="medium" v-model="params.status">
          <el-option label="全部" value></el-option>
          <el-option label="启用中" value="1"></el-option>
          <el-option label="禁用中" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词">
        <el-input
          size="medium"
          v-model="params.card_name"
          placeholder="请输入关键词"
        ></el-input>
      </el-form-item>
      <div class="col-auto">
        <el-button type="primary" size="small" @click="searchCard">搜索</el-button>
      </div>
      <div class="col"></div>
      <div class="col-auto">
        <el-button type="primary" size="small" @click="onSubmit">创建充值卡</el-button>
      </div>
    </template>


    <template slot="table-columns">
      <el-table-column label="卡卷名称" fixed="left" min-width="200">
        <template slot-scope="scope">{{ scope.row.card_name }}</template>
      </el-table-column>
      <el-table-column label="所属商城" width="150">
        <template slot-scope="scope">{{ scope.row.shop_name }}</template>
      </el-table-column>
      <el-table-column label="创建时间" width="160">
        <template slot-scope="scope">{{
            scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss')
          }}
        </template>
      </el-table-column>
      <el-table-column label="有效期" width="300">
        <template slot-scope="scope"
        >{{
            scope.row.start_time | unixToDate('yyyy-MM-dd hh:mm:ss')
          }}~{{
            scope.row.end_time | unixToDate('yyyy-MM-dd hh:mm:ss')
          }}
        </template
        >
      </el-table-column>

      <el-table-column label="兑换码总数" width="100">
        <template slot-scope="scope">{{
            scope.row.key_num ? scope.row.key_num : 0
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">{{
            scope.row.status === 1 ? '启用中' : '禁用中'
          }}
        </template>
      </el-table-column>
      <el-table-column label="管理" width="300" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="primary"
            v-if="$store.getters.shopInfo.shop_type == 3 ? true : false"
            @click="distribution(scope.$index, scope.row)"
            >在线发放</el-button
          > -->
          <el-button
            size="mini"
            type="primary"
            @click="gotwo(scope.row.id)"
          >生成
          </el-button
          >
          <el-button size="mini" @click="goDhm(scope.$index, scope.row)"
          >兑换码
          </el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleEdit(scope.$index, scope.row)"
          >编辑
          </el-button
          >
          <!-- <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >删除</el-button> -->
          <el-button
            size="mini"
            type="danger"
            @click="handleDeleteStatus(scope.row)"
          >{{ scope.row.status === 1 ? '禁用' : '启用' }}
          </el-button
          >
        </template>
      </el-table-column>
    </template>

    <el-pagination
      background
      slot="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage3"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      :page-size="params.page_size"
      :total="total"
    ></el-pagination>
  </en-table-layout>

  <!--  <grant-->
  <!--    v-bind:yfather="czkmsg"-->
  <!--    class="distribu"-->
  <!--    v-if="distributionflag"-->
  <!--  ></grant>-->
</template>
<script>
import * as API_coupon from '@/api/coupon';
import grant from './grant';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  components: {
    EnTableLayout,
    grant
  },
  data() {
    return {
      total: 0,
      flag: true,
      distributionflag: false,
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: '',
        shopDisable: '',
        keyword: '',
        card_type: 2,
        card_name: '',
        status: ''
      },
      currentPage3: 1,
      pageData: {data: []},
      selectedData: [],
      search: '',
      czkmsg: '',
      formInline: {
        card_name: '',
        page_no: 1,
        page_size: 20,
        status: ''
      }
    };
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'couponList') {
        this.params.card_type = 2;
        this.flag = true;
        this.getyhj();
      } else if (this.$route.name === 'cashCouponList') {
        this.params.card_type = 3;
        this.flag = false;
        this.getyhj();
      } else {
        this.params.card_type = 4;
        this.flag = false;
        this.getyhj();
      }
    }
  },
  created() {
    if (this.$route.name === 'couponList') {
      this.flag = true;
      this.params.card_type = 2;
      this.getyhj();
    } else if (this.$route.name === 'czkMealList') {
      this.flag = false;
      this.params.card_type = 4;
      this.getyhj();
    } else {
      this.flag = false;
      this.params.card_type = 3;
      this.getyhj();
    }
    this.getyhj();
  },
  methods: {
    distribution(index, row) {
      this.czkmsg = row.id;
      this.distributionflag = true;
      // this.czkmsg =
    },
    goDhm(index, row) {
      if (this.$route.name === 'czkMealList') {
        this.$router.push({
          path: '/application/exchangeCode1',
          query: {card_id: row.id}
        });
      } else {
        this.$router.push({
          path: '/promotion/exchangeCode',
          query: {card_id: row.id}
        });
      }
    },
    gotwo(id) {
      if (this.$route.name === 'cashCouponList') {
        this.$router.push({
          path: '/promotion/djjadd',
          query: {
            active: 2,
            card_id: id
          }
        });
      } else if (this.$route.name === 'czkMealList') {
        this.$router.push({
          path: '/application/czkadd',
          query: {
            active: 2,
            card_id: id
          }
        });
      } else {
        this.$router.push({
          path: '/promotion/yhjadd',
          query: {
            active: 2,
            card_id: id
          }
        });
      }
    },
    searchCard() {
      this.formInline.card_type = this.params.card_type;
      API_coupon.getDhmlist(this.params).then(res => {
        this.pageData = res;
        this.total = res.data_total;
      });
    },
    getyhj() {
      let params = this.params;
      API_coupon.getDhmlist(params).then(res => {
        this.pageData = res;
        this.total = res.data_total;
      });
    },
    handleSelectionChange2(val) {
      this.selectedData = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.params.page_no = val;
      this.getyhj();
      console.log(`当前页: ${val}`);
    },
    handleEdit(index, row) {
      if (row.card_type === 3) {
        this.$router.push({
          path: '/promotion/djjadd',
          query: {id: row.id}
        });
      } else if (row.card_type === 2) {
        this.$router.push({
          path: '/promotion/yhjadd',
          query: {id: row.id}
        });
      } else {
        this.$router.push({
          path: '/application/czkadd',
          query: {id: row.id}
        });
      }
    },
    handleDeleteStatus(row) {
      let {
        id,
        status
      } = row;
      // updateStatus
      this.$confirm(
        `确定${status === 1 ? '禁用' : '启用'}此卡券，是否继续?`,
        '提示',
        {type: 'warning'}
      )
        .then(() => {
          API_coupon.updateStatus({
            id: id,
            status: status === 1 ? 0 : 1
          }).then(res => {
            this.getyhj();
            this.$message.success('操作成功！');
          });
        })
        .catch(() => {
        });
    },
    handleDelete(index, row) {
      let id = [row.id];
      this.$confirm(`确定删除此卡券，是否继续?`, '提示', {type: 'warning'})
        .then(() => {
          API_coupon.cardDelete({ids: id}).then(res => {
            this.getyhj();
          });
        })
        .catch(() => {
        });
    },
    onSubmit() {
      if (this.$route.name === 'cashCouponList') {
        this.$router.push({path: '/promotion/djjadd'});
      } else if (this.$route.name === 'czkMealList') {
        this.$router.push({path: '/application/czkadd'});
      } else {
        this.$router.push({path: '/promotion/yhjadd'});
      }
    }
  }
};
</script>

<style lang="scss">
</style>
